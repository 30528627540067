import React from 'react';
import LoginRequired from '../Login/LoginRequired';

export default function Preferences() {
  if(!sessionStorage.getItem('token')){
    return(
      <LoginRequired />
    );
  }

  return(
		<div className="section-container preferences">
    	<h2>Preferences</h2>
		</div>
  );
}
