import React from 'react';
import TextInput from '../../formElements/TextInput';

export default function UserPassword({
  passwordObj, handlePasswordChange
}) {

  return(
    <div>
      <div className="page-count-container">
        <div className="page-count-label">5/5</div>
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="password"> <span className='red-color' >*</span> Password: </label>
        </div>
        <TextInput
          id="password"
          type="password"
          placeholder="password"
          inputClassName='soft-border shadow-bottom-right'
          value={passwordObj.value}
          size='25'
          errorMessage={passwordObj.errorMessage}
          errorClassName={passwordObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handlePasswordChange(e.target.value)}
          autoComplete="new-password"
        />
      </div>
    </div>
  );
}
