import React from 'react';
import TextInput from '../../formElements/TextInput';

export default function UserContact({
  emailObj, handleEmailChange,
  countryCodeObj, handleCountryCodeChange,
  areaCodeObj, handleAreaCodeChange,
  telephonePrefixObj, handleTelephonePrefixChange,
  lineNumberObj, handleLineNumberChange
}) {

  return(
    <div>
      <div className="page-count-container">
        <div className="page-count-label">3/5</div>
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="email"> <span className='red-color' >*</span> Email Address: </label>
        </div>
        <TextInput
          id="email"
          type="text"
          placeholder="name@email.com"
          inputClassName=' soft-border shadow-bottom-right'
          value={emailObj.value}
          size='25'
          errorMessage={emailObj.errorMessage}
          errorClassName={emailObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleEmailChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="areaCode"> Phone Number: </label>
        </div>
        <div className="form-data" style={{display:'flex',flexDirection:'row'}}>
          <div id='country-code-container'>
            <TextInput
              id="countryCode"
              placeholder=""
              inputClassName='text-align-right soft-border shadow-bottom-right'
              maxlength='7'
              size='4'
              value={countryCodeObj.value}
              errorMessage={countryCodeObj.errorMessage}
              errorClassName={countryCodeObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleCountryCodeChange(e.target.value)}
            />
          </div>
          <div>
          &nbsp;
          </div>
          <div>
          (
          </div>
          <div id='area-code-container' >
            <TextInput
              id="areaCode"
              placeholder="123"
              inputClassName=' soft-border shadow-bottom-right'
              value={areaCodeObj.value}
              maxlength='3'
              size='3'
              errorMessage={areaCodeObj.errorMessage}
              errorClassName={areaCodeObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleAreaCodeChange(e.target.value)}
            />
          </div>
          <div>
            )
          </div>
          <div id ='telephone-prefix-container'>
            <TextInput
              id="telephonePrefix"
              placeholder="456"
              inputClassName=' soft-border shadow-bottom-right'
              value={telephonePrefixObj.value}
              maxlength='3'
              size='3'
              errorMessage={telephonePrefixObj.errorMessage}
              errorClassName={telephonePrefixObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleTelephonePrefixChange(e.target.value)}
            />
          </div>
          <div>
          -
          </div>
          <div id='line-number-container'>
            <TextInput
              id="lineNumber"
              placeholder="7890"
              inputClassName=' soft-border shadow-bottom-right'
              value={lineNumberObj.value}
              maxlength='4'
              size='4'
              errorMessage={lineNumberObj.errorMessage}
              errorClassName={lineNumberObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleLineNumberChange(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
