export const getStarterValue = (storageObj, val, defaultVal='') => {
	if(sessionStorage[storageObj] && JSON.parse(sessionStorage[storageObj])[val]){
		return JSON.parse(sessionStorage[storageObj])[val];
	} else if(localStorage[storageObj] && JSON.parse(localStorage[storageObj])[val]){
		return JSON.parse(localStorage[storageObj])[val];
	}
	return defaultVal;
}
export const getStarterSubValue = (storageObj, cat, val, defaultVal='') => {
	if(sessionStorage[storageObj] && JSON.parse(sessionStorage[storageObj])[cat]){
		return JSON.parse(sessionStorage[storageObj])[cat][val];
	} else if(localStorage[storageObj] && JSON.parse(localStorage[storageObj])[cat]){
		return JSON.parse(localStorage[storageObj])[cat][val];
	}
	return defaultVal;
}
export const getStarterSubSubValue = (storageObj, cat, obj, val, defaultVal='') => {
	if(sessionStorage[storageObj] && JSON.parse(sessionStorage[storageObj])[cat] && JSON.parse(sessionStorage[storageObj])[cat][obj]){
		return JSON.parse(sessionStorage[storageObj])[cat][obj][val];
	} else if(localStorage[storageObj] && JSON.parse(localStorage[storageObj])[cat] && JSON.parse(sessionStorage[storageObj])[cat][obj]){
		return JSON.parse(localStorage[storageObj])[cat][obj][val];
	}
	return defaultVal;
}
export const getStarterValueObj = (storageObj, val, defaultVal='', required=false) => {
	let pObj = {
		value: defaultVal,
		errorMessage: '',
		errorClassName: 'no-error',
		valid:true
	}
	if(sessionStorage[storageObj] && JSON.parse(sessionStorage[storageObj])[val]){
		pObj.value = JSON.parse(sessionStorage[storageObj])[val];
	} else if(localStorage[storageObj] && JSON.parse(localStorage[storageObj])[val]){
		pObj.value = JSON.parse(localStorage[storageObj])[val];
	}
	try{
		if(required && pObj.value.trim() === ''){
			pObj.valid = false;
		}
	} catch(e){
		console.log('ERROR!!!!! ', e);
	}
	return pObj;
}
export const getStarterSubValueObj = (storageObj, cat, val, defaultVal='', required=false) => {
	let pObj = {
		value:defaultVal,
		errorMessage:'',
		errorClassName:'no-error',
		valid:true
	}
	if(sessionStorage[storageObj] && JSON.parse(sessionStorage[storageObj])[cat]){
		pObj.value = JSON.parse(sessionStorage[storageObj])[cat][val];
	} else if(localStorage[storageObj] && JSON.parse(localStorage[storageObj])[cat]){
		pObj.value = JSON.parse(localStorage[storageObj])[cat][val];
	}
	try{
		if(required && pObj.value.trim() === ''){
			pObj.valid = false;
		}
	} catch(e){
		console.log('ERROR!!!!! ', e);
	}
	return pObj;
}
export const getStarterSubSubValueObj = (storageObj, cat, obj, val, defaultVal='', required=false) => {
	let pObj = {
		value:defaultVal,
		errorMessage:'',
		errorClassName:'no-error',
		valid:true
	}
	if(sessionStorage[storageObj] && JSON.parse(sessionStorage[storageObj])[cat] && JSON.parse(sessionStorage[storageObj])[cat][obj]){
		pObj.value = JSON.parse(sessionStorage[storageObj])[cat][obj][val];
	} else if(localStorage[storageObj] && JSON.parse(localStorage[storageObj])[cat] && JSON.parse(sessionStorage[storageObj])[cat][obj]){
		pObj.value = JSON.parse(localStorage[storageObj])[cat][obj][val];
	}
	try{
		if(required && pObj.value.trim() === ''){
			pObj.valid = false;
		}
	} catch(e){
		console.log('ERROR!!!!! ', e);
	}
	return pObj;
}

// module.exports = { getStarterValue, getStarterSubValue, getStarterSubSubValue, getStarterValueObj, getStarterSubValueObj, getStarterSubSubValueObj }
