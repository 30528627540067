import React from 'react';
import './formElements.css';

export default function StateSelect({ value, onChangeFunct, selectClassName, optionClassName, containerClass }){

	return(
		<div className={containerClass}>
			<select
				id="state"
				name="state"
				value={(!value || value.trim()==='')?'TX':value}
				onChange={onChangeFunct}
				className={selectClassName}
				>
			    <option value="AL"		className={optionClassName}>Alabama</option>
			    <option value="AK"		className={optionClassName}>Alaska</option>
			    <option value="AS"		className={optionClassName}>American Samoa</option>
			    <option value="AZ"		className={optionClassName}>Arizona</option>
			    <option value="AR"		className={optionClassName}>Arkansas</option>
			    <option value="UM-81" className={optionClassName}>Baker Island</option>
			    <option value="CA"		className={optionClassName}>California</option>
			    <option value="CO"		className={optionClassName}>Colorado</option>
			    <option value="CT"		className={optionClassName}>Connecticut</option>
			    <option value="DE"		className={optionClassName}>Delaware</option>
			    <option value="DC"		className={optionClassName}>District of Columbia</option>
			    <option value="FL"		className={optionClassName}>Florida</option>
			    <option value="GA"		className={optionClassName}>Georgia</option>
			    <option value="GU"		className={optionClassName}>Guam</option>
			    <option value="HI"		className={optionClassName}>Hawaii</option>
			    <option value="UM-84" className={optionClassName}>Howland Island</option>
			    <option value="ID"		className={optionClassName}>Idaho</option>
			    <option value="IL"		className={optionClassName}>Illinois</option>
			    <option value="IN"		className={optionClassName}>Indiana</option>
			    <option value="IA"		className={optionClassName}>Iowa</option>
			    <option value="UM-86" className={optionClassName}>Jarvis Island</option>
			    <option value="UM-67" className={optionClassName}>Johnston Atoll</option>
			    <option value="KS"		className={optionClassName}>Kansas</option>
			    <option value="KY"		className={optionClassName}>Kentucky</option>
			    <option value="UM-89" className={optionClassName}>Kingman Reef</option>
			    <option value="LA"		className={optionClassName}>Louisiana</option>
			    <option value="ME"		className={optionClassName}>Maine</option>
			    <option value="MD"		className={optionClassName}>Maryland</option>
			    <option value="MA"		className={optionClassName}>Massachusetts</option>
			    <option value="MI"		className={optionClassName}>Michigan</option>
			    <option value="UM-71" className={optionClassName}>Midway Atoll</option>
			    <option value="MN"		className={optionClassName}>Minnesota</option>
			    <option value="MS"		className={optionClassName}>Mississippi</option>
			    <option value="MO"		className={optionClassName}>Missouri</option>
			    <option value="MT"		className={optionClassName}>Montana</option>
			    <option value="UM-76" className={optionClassName}>Navassa Island</option>
			    <option value="NE"		className={optionClassName}>Nebraska</option>
			    <option value="NV"		className={optionClassName}>Nevada</option>
			    <option value="NH"		className={optionClassName}>New Hampshire</option>
			    <option value="NJ"		className={optionClassName}>New Jersey</option>
			    <option value="NM"		className={optionClassName}>New Mexico</option>
			    <option value="NY"		className={optionClassName}>New York</option>
			    <option value="NC"		className={optionClassName}>North Carolina</option>
			    <option value="ND"		className={optionClassName}>North Dakota</option>
			    <option value="MP"		className={optionClassName}>Northern Mariana Islands</option>
			    <option value="OH"		className={optionClassName}>Ohio</option>
			    <option value="OK"		className={optionClassName}>Oklahoma</option>
			    <option value="OR"		className={optionClassName}>Oregon</option>
			    <option value="UM-95" className={optionClassName}>Palmyra Atoll</option>
			    <option value="PA"		className={optionClassName}>Pennsylvania</option>
			    <option value="PR"		className={optionClassName}>Puerto Rico</option>
			    <option value="RI"		className={optionClassName}>Rhode Island</option>
			    <option value="SC"		className={optionClassName}>South Carolina</option>
			    <option value="SD"		className={optionClassName}>South Dakota</option>
			    <option value="TN"		className={optionClassName}>Tennessee</option>
			    <option value="TX"		className={optionClassName}>Texas</option>
			    <option value="UM"		className={optionClassName}>United States Minor Outlying Islands</option>
			    <option value="VI"		className={optionClassName}>United States Virgin Islands</option>
			    <option value="UT"		className={optionClassName}>Utah</option>
			    <option value="VT"		className={optionClassName}>Vermont</option>
			    <option value="VA"		className={optionClassName}>Virginia</option>
			    <option value="UM-79" className={optionClassName}>Wake Island</option>
			    <option value="WA"		className={optionClassName}>Washington</option>
			    <option value="WV"		className={optionClassName}>West Virginia</option>
			    <option value="WI"		className={optionClassName}>Wisconsin</option>
			    <option value="WY"		className={optionClassName}>Wyoming</option>
			</select>
		</div>
	);
}
