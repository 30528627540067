import React from 'react';
import LoginRequired from '../Login/LoginRequired';

export default function ListContributor() {
  if(!sessionStorage.getItem('token')){
    return(<LoginRequired />);
  }
  return(
		<div className="section-container contributors">
    	<h2>List Contributor</h2>
		</div>
  );
}
