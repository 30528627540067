import React from 'react';
import { useNavigate } from 'react-router-dom';

const MainMenu = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    sessionStorage.removeItem('currentPath');
    sessionStorage.removeItem('subPath');
    navigate(path);
  }

  return(
    <div className='footerMenu-container'>
      <div className='footerMenu-button gradient' onClick={() => handleClick('/')}>Home</div>
      <div className='footerMenu-button gradient' onClick={() => handleClick('/about')}>About Us</div>
      <div className='footerMenu-button gradient' onClick={() => handleClick('/contact')}>Contact Us</div>
      <div className='footerMenu-button gradient' onClick={() => handleClick('/other')}>Other</div>
    </div>
  )
}

export default MainMenu;
