import React, { useState } from 'react';
import './Login.css';
import PropTypes from 'prop-types';
import Messages from '../Messages/Messages';
import { useNavigate } from 'react-router-dom';
import {
  loginUser
} from '../helperFunctions/webserviceFunctions';


// async function loginUser(credentials) {
//  return fetch('/login/', {
// 	method: 'POST',
// 	mode: 'cors',
// 	headers: {'Content-Type': 'application/json'},
// 	body: JSON.stringify(credentials)
//  })
//    .then(data => data.json())
// }


export default function Login({ setToken, setMessage }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const redirect = (path) => {
    navigate(path);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    sessionStorage.removeItem('message');
    const results = await loginUser({
      username,
      password
    });
    if(results.message === "success"){
      setToken(results);
      let message = "Welcome, " + results.name + ". ";
      if(results.messages){
        message += results.messages;
      }
      setMessage(message);
      sessionStorage.setItem('expires', results.expires);
      sessionStorage.removeItem('error');
      redirect('/');
    } else {
      setToken({token:null});
      setMessage(results.message);
      sessionStorage.setItem('error', results.error);
    }
  }

  if(sessionStorage.getItem('token')){
    return(
      <div className='section-container login-wrapper'>
        <h1>ALREADY LOGGED IN!!</h1>
      </div>
    );
  }

  return(
    <div className='section-container login-wrapper'>
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username/Email</p>
          <input name="email" id="email" type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input name="password" id="password" type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
        <div className='create-new-user-link-container'>
          <div className="href-link" onClick={()=> redirect('/feuserwizard')}>Sign Up</div>
        </div>
				<div>
					<Messages />
				</div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
