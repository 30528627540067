import React, { useState } from 'react';
import LoginRequired from '../../Login/LoginRequired';
import {
  getToken
} from '../BudgetHelperFunctions';
import {
  callWebServicePost
} from '../../helperFunctions/webserviceFunctions';

export default function DeleteBudget() {
  const [ contents, setContents ] = useState('<></>');
  // get the list of budgets
  let tokenObj = {
    token: getToken()
  };
  if(!tokenObj.token){
    return(
      <LoginRequired />
    );
  }
  // const resultsObj =
  callWebServicePost(tokenObj, 'read/budgets', (resultsObj) => {
    console.log('resultsObj: ', resultsObj);
  });

  // build the form

  // call the confirmation
  // Delete Budget
  // function deleteBudget(budgetCode){
  //   // delete the collections
  //   // delete the record in user
  //   // delete the record in budgets
  //   // delete the record in the session
  // }
  async function deleteBudget(budgetCode){
    // const tokenObj = JSON.parse(sessionStorage.getItem('token'));
    const deleteObj = {
      budgetCode: budgetCode,
      sessionId:tokenObj?.token
    };
    const results = await callWebServicePost(deleteObj, 'delete/budget');
    console.log('Delete Results: ', results);
  }

  return(
		<div className="section-container budget">
    	<h2>Delete Budget</h2>
		</div>
  );
}
