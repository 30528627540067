import React from 'react';
import TextInput from '../../formElements/TextInput';

export default function BudgetInfoForm({
  nameObj,        handleNameChange,
  descriptionObj, handleDescriptionChange
}) {

  return(
    <div className="wizard-container">
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="name"> <span className='red-color' >*</span> Name: </label>
        </div>
        <TextInput
          id="name"
          type="text"
          placeholder="Budget Name"
          inputClassName='soft-border shadow-bottom-right'
          value={nameObj.value}
          size='10'
          errorMessage={nameObj.errorMessage}
          errorClassName={nameObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleNameChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="description">  Description: </label>
        </div>
        <TextInput
          id="description"
          placeholder="Describe the database here"
          inputClassName='soft-border shadow-bottom-right'
          value={descriptionObj.value}
          size='25'
          errorMessage={descriptionObj.errorMessage}
          errorClassName={descriptionObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleDescriptionChange(e.target.value)}
        />
      </div>
    </div>
  );
}
