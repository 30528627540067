import React from 'react';
import LoginRequired from '../Login/LoginRequired';
import { useNavigate } from 'react-router-dom';
import useBudgetObj from '../../hooks/useBudgetObj';
import './Budget.css';

export default function Budget() {
  const navigate = useNavigate();
  const { setBudgetObj } = useBudgetObj();

  async function getBudget(budgetId) {
    // let credentials = {id:budgetId}
    console.log('GETBUDGET: ', budgetId);
    let url = 'http://127.0.0.1:3260/read/budget/' + budgetId;
    const results = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'}
    }).then(data => data.json());
    setBudgetObj(results);
  }

  const handleClick = (path) => {
    sessionStorage.setItem('subPath',path);
    navigate(path);
  }
  const selectAccount = (id) => {
    getBudget(id);
  }

  const createRandomString = (numOfChars) => {
    const values='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randString = '';
    for(let i=0; i<numOfChars; i++){
      randString += values.substr(Math.floor(Math.random() * values.length),1);
    }
    return randString;
  }

  if(!sessionStorage.getItem('token')){
    return(
      <LoginRequired />
    );
  }

  let createBudgetButton = '';
  let budgetInformation = '';
  const tokenObj = JSON.parse(sessionStorage.getItem('token'));
	// const token = tokenObj?.token;
  const budgetObj = JSON.parse(sessionStorage.getItem('budgetObj'));

  createBudgetButton = (!budgetObj || budgetObj === undefined || budgetObj?.accountCount < 1)?<div>You do not have a budget, yet.<br/><div className='userMenu-button' onClick={() => handleClick('/fecreatebudget')}>Create</div></div>:'';

  if(budgetObj && budgetObj?.accountCount > 1){
    const accountArray = budgetObj?.accnts.map((accnt)=>{
      return <div key={accnt} onClick={()=>selectAccount(accnt)}>{accnt}</div>;
    });
    budgetInformation = <div>
      {budgetObj.message} <br/> {budgetObj.accountCount} <br /> {accountArray}
    </div>;
  } else if(budgetObj) {
    budgetInformation = <div className='bInfo-container'>
        <div className='bInfo'>
          <div className='bName-container'><div className='bName-title'>NAME:</div><div className='bName-info'>{budgetObj?.bName}</div></div>
          <div className='bKey-container'><div className='bKey-title'>Description:</div><div className='bKey-info'>{budgetObj?.bDesc}</div></div>
          <div className='bKey-container'><div className='bKey-title'>Date:</div><div className='bKey-info'>{budgetObj?.bDC}</div></div>
        </div>
        <div className='contextMenu-container budget-buttons-container'>
          <div className='userMenu-button' onClick={() => handleClick('/felistbudget')}>Show the whole budget</div>
          <div className='userMenu-button' onClick={() => handleClick('/feupdatebudget')}>Update the budget</div>
          <div className='userMenu-button' onClick={() => handleClick('/fedeletebudget')}>Delete this budget</div>
        </div>
      </div>;
  }
  return(
		<div className="section-container budget">
    	<div className='page-title'>Budget</div>
      {createBudgetButton}
      {budgetInformation}
      <div onClick={() => alert(createRandomString(5))}>Show Random String</div>
		</div>
  );
}
