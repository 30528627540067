import React, { useState } from 'react';
import LoginRequired from '../../Login/LoginRequired';
import WizardNavigator from '../../wizards/menus/WizardNavigator';
import BudgetInfoForm from './BudgetInfoForm';
import '../../wizards/styles/wizards.css';
import {
  getStarterSubSubValueObj,
  getStarterValueObj,
  getStarterSubValueObj
} from '../../helperFunctions/getStartedFunctions';
import {
  callWebServicePost
} from '../../helperFunctions/webserviceFunctions';
import {
  handleNameChange,
	handleDescriptionChange
} from '../BudgetHelperFunctions';

export default function CreateBudget({ setToken, setMessage }) {
  const [ page,          setPage ]           = useState('start');
  const [ nameObj,       setNameObj ]        = useState(getStarterValueObj('budgetFormObj','name', '', true));
  const [ descriptionObj,setDescriptionObj ] = useState(getStarterValueObj('budgetFormObj', 'description'));
  const [ budgetItemsObj,setBudgetItemsObj ] = useState(getStarterValueObj('budgetFormObj', 'budgetItems'));


  if(!sessionStorage.getItem('token')){
    return(
      <LoginRequired />
    );
  }


  function infoIsValid(){
    let valid = true;
    return valid;
  }
  function itemValuesAreValid(){
    let valid = true;
    return valid;
  }
  async function deleteBudget(bc){
    const tokenObj = JSON.parse(sessionStorage.getItem('token'));
    const deleteObj = {
      budgetCode: bc,
      sessionId:tokenObj?.token
    };
    const results = await callWebServicePost(deleteObj, 'delete/budget');
    console.log('Delete Results: ', results);
  }

  const handleClick = async (value) => {
    const tokenObj = JSON.parse(sessionStorage.getItem('token'));
    const budgetFormObj = {
      name: nameObj.value,
      description:descriptionObj.value,
      budgetItems:budgetItemsObj.value,
      sessionId:tokenObj?.token,
      userId:tokenObj?.insertedId
    };

    if(page === 'info' && value === 'items'){
      const results = await callWebServicePost(budgetFormObj, 'create/budget');
      console.log('results:', results);
    }

    sessionStorage.setItem('budgetFormObj', JSON.stringify(budgetFormObj));
    localStorage.setItem('budgetFormObj', JSON.stringify(budgetFormObj));

    setPage(value);
  }

  let contents = <></>;
  try{
    document.getElementById('new-account-wizard-container').scroll({top:0,behavior:'smooth'});
  } catch(e) {
    // no action needed
  }
  switch(page){
    case 'info':
      contents=<>
        <h1>Info</h1>
        <BudgetInfoForm
          nameObj={nameObj}               handleNameChange={(newVal) => handleNameChange(newVal, nameObj, (obj)=>setNameObj(obj))}
          descriptionObj={descriptionObj} handleDescriptionChange={(newVal) => handleDescriptionChange(newVal, descriptionObj, (obj)=>setDescriptionObj(obj))}
        />
        <button onClick={()=>{deleteBudget('aXYzj')}}>Delete Budget</button>
        <WizardNavigator
          menuItems={[{title:'<< Previous',value:'start',active:true},{title:'Next >>',value:'items',active:infoIsValid()}]}
          handleClick={handleClick}
        />
      </>;
      break;
    case 'items':
      contents=<>
        <h1>Items</h1>
        <WizardNavigator
          menuItems={[{title:'<< Previous',value:'info',active:true},{title:'Done',value:'SUBMIT',active:itemValuesAreValid()}]}
          handleClick={handleClick}
        />
      </>;
      break;
    case 'SUBMIT':
      contents=<>
      <h1>SUBMIT</h1>
      <WizardNavigator
        menuItems={[{title:'Back To Start',value:'start',active:true}]}
        handleClick={handleClick}
      />
      </>;
      sessionStorage.removeItem('budgetFormObj');
      localStorage.removeItem('budgetFormObj');
      break;
    default:
      contents = <div className='wizard-nav-button-container'>
      <div className='href-link wizard-nav-button' onClick={()=>setPage('info')}> Start &gt;&gt; </div></div>;
  }

  return(
		<div className="section-container budget">
      <div className='wizard-title'>Budget Creation Wizard</div>
    	{contents}
		</div>
  );
}
