import React from 'react';
import { useNavigate } from 'react-router-dom';

const UserMenu = ({ setBudgetObj }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    sessionStorage.setItem('currentPath',path);
    sessionStorage.removeItem('subPath');
    navigate(path);
  }

  async function getBudget(sessionId) {
    let credentials = {id:sessionId}

    const results = await fetch('http://127.0.0.1:3260/read/budget/session', {
      method: 'POST',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(credentials)
    }).then(data => data.json());

    setBudgetObj(results);
    return results;
  }

  const getBudgetObject = () => {
    const tokenObj = JSON.parse(sessionStorage.getItem('token'));
  	const token = tokenObj?.token;
    const budget = getBudget(token);
  }

  if(!sessionStorage.getItem('token')){
    return(
      <></>
    );
  }
  return(
    <div className='userMenu-container'>
			<div className='userMenu-button gradient' onClick={() => {getBudgetObject();handleClick('/febudget')}}>Budget</div>
			<div className='userMenu-button gradient' onClick={() => handleClick('/fecontributors')}>Contributors</div>
			<div className='userMenu-button gradient' onClick={() => handleClick('/feactivities')}>Activity</div>
			<div className='userMenu-button gradient' onClick={() => handleClick('/fecontributions')}>Contributions</div>
    </div>
  )
}

export default UserMenu;
