import { useState } from 'react';

export default function useMessage() {
  const [message, setMessage] = useState(sessionStorage.getItem('message'));

  const saveMessage = userMessage => {
    sessionStorage.setItem('message', userMessage);
    setMessage(userMessage);
  };

  return {
    setMessage: saveMessage,
    message
  }
}
