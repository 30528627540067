import React from 'react';
import { useNavigate } from 'react-router-dom';



const MainMenu = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    sessionStorage.removeItem('currentPath');
    sessionStorage.removeItem('subPath');
    navigate(path);
  }

  return(
    <div className='mainMenu-container'>
      <div className='mainMenu-button gradient' onClick={() => handleClick('/')}>Home</div>
      <div className='mainMenu-button gradient' onClick={() => handleClick('/works')}>How It Works</div>
      <div className='mainMenu-button gradient' onClick={() => handleClick('/helps')}>How It Helps</div>
      <div className='mainMenu-button gradient' onClick={() => handleClick('/share')}>How To Share</div>
    </div>
  )
}

export default MainMenu;
