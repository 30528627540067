import React from 'react';

export default function ListActivity() {
  if(!sessionStorage.getItem('token')){
    return(
      <div className="section-container preferences">
        <h1>Please log into the system to use this resource.</h1>
      </div>
    );
  }
  return(
		<div className="section-container activities">
    	<h2>List Activity</h2>
		</div>
  );
}
