import React from 'react';
import LoginRequired from '../Login/LoginRequired';

export default function Dashboard() {
  if(!sessionStorage.getItem('token')){
    return(
      <LoginRequired />
    );
  }
  return(
		<div className="section-container dashboard">
    	<h2>Dashboard</h2>
		</div>
  );
}
