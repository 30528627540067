import React from 'react';
import useToken from '../../../hooks/useToken';
import useMessage from '../../../hooks/useMessage';
import { useNavigate } from 'react-router-dom';
import './Actions.css';
import {
  logoutUser
} from '../../helperFunctions/webserviceFunctions';


// async function logoutUser(token) {
// 	let url = '/logout/'+token;
// 	return fetch(url, {
// 		method: 'GET',
// 		mode: 'cors'
// 	})
// 		.then(data => data.json());
// }


const Actions = ({setToken}) => {
	const tokenObj = JSON.parse(sessionStorage.getItem('token'));
	const token = tokenObj?.token;
	const { setMessage } = useMessage();
	const navigate = useNavigate();

	const handleLogout = async () => {
		const results = await logoutUser(token);
		setToken(results);
		setMessage(results.message);
		sessionStorage.removeItem('expires');
		sessionStorage.removeItem('budgetObj');
	}

	const handleClick = (path) => {
    sessionStorage.setItem('currentPath',path);
    sessionStorage.removeItem('subPath');
		navigate(path);
	}

	if(!sessionStorage.getItem('token')){
		return(
			<div>
				<button onClick={() => {handleClick('/loginForm')}}> LOGIN </button>
			</div>
		);
	}

	return(
		<div className='action-container'>
			<button onClick={() => {handleLogout()}}> LOGOUT </button>
			<button onClick={() => {handleClick('/dashboard')}}> Dashboard </button>
			<button onClick={() => {handleClick('/preferences')}}> Preferences </button>
		</div>
	)
}

export default Actions;
