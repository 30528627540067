import React from 'react';
import './Copyright.css';

export default function Copyright() {

  return(
		<div className="copyright">
    	JMLatham LLC &#169; 2023
		</div>
  );
}
