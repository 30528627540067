import React from 'react';
import Copyright from '../Copyright/Copyright';
import FooterMenu from '../menus/FooterMenu';
import logo from '../../jml_logo.svg';
import './Footer.css';

const Footer = () => {
  return(
    <div className='section-container footer'>
      <div className='logo-container mini'>
        <img src={logo} className="App-logo mini" alt="logo" width="25" />
				<Copyright />
      </div>
      <div className='footer menu'>
        <FooterMenu />
      </div>
    </div>
  )
}

export default Footer;
