import React from 'react';
import logo from '../../jml_logo.svg';
import MainMenu from '../menus/MainMenu';
import UserMenu from '../menus/UserMenu';
import ContextMenu from '../menus/ContextMenu';
import Actions from './Actions/Actions';

const Header = ({ setToken, setBudgetObj }) => {

  return(
    <div className='section-container header'>
      <div className='logo-container'>
        <img src={logo} alt="logo" width="75" />
      </div>
      <div className='navigation-container'>
        <MainMenu />
				<UserMenu setBudgetObj={setBudgetObj} />
				<ContextMenu />
      </div>
			<div className='action-container'>
				<Actions setToken={setToken} />
			</div>
    </div>
  )
}

export default Header;
