import React from 'react';
import TextInput from '../../formElements/TextInput';

export default function UserDates({
  birthdateObj, handleBirthDateChange
}) {

  return(
    <div>
      <div className="page-count-container">
        <div className="page-count-label">4/5</div>
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="bDate"> Birthdate: </label>
        </div>
        <TextInput
          id="bDate"
          type="date"
          placeholder=''
          inputClassName='soft-border shadow-bottom-right'
          min="1900-01-01"
          max="2100-12-31"
          value={birthdateObj.value}
          size='25'
          errorMessage={birthdateObj.errorMessage}
          errorClassName={birthdateObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleBirthDateChange(e.target.value)}
        />
      </div>
    </div>
  );
}
