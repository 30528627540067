import React from 'react';
import TextInput from '../../formElements/TextInput';

export default function UserName({
  titleObj,     handleTitleChange,
  firstNameObj, handleFirstNameChange,
  middleNameObj,handleMiddleNameChange,
  lastNameObj,  handleLastNameChange,
  suffixObj,    handleSuffixChange
}) {

  return(
    <div className="wizard-container user name">
      <div className="page-count-container">
        <div className="page-count-label">1/5</div>
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="title"> Title: </label>
        </div>
        <TextInput
          id="title"
          type="text"
          placeholder="Mr./Mrs."
          inputClassName='soft-border shadow-bottom-right'
          value={titleObj.value}
          size='10'
          errorMessage={titleObj.errorMessage}
          errorClassName={titleObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleTitleChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="firstName"> <span className='red-color' >*</span> First Name: </label>
        </div>
        <TextInput
          id="firstName"
          placeholder="first name"
          inputClassName='soft-border shadow-bottom-right'
          value={firstNameObj.value}
          size='25'
          errorMessage={firstNameObj.errorMessage}
          errorClassName={firstNameObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleFirstNameChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="middleName"> Middle Name: </label>
        </div>
        <TextInput
          id="middleName"
          placeholder="middle name"
          inputClassName='soft-border shadow-bottom-right'
          value={middleNameObj.value}
          size='25'
          errorMessage={middleNameObj.errorMessage}
          errorClassName={middleNameObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleMiddleNameChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="lastName"> <span className='red-color' >*</span> Last Name: </label>
        </div>
        <TextInput
          id="lastName"
          placeholder="last name"
          inputClassName='soft-border shadow-bottom-right'
          value={lastNameObj.value}
          size='25'
          errorMessage={lastNameObj.errorMessage}
          errorClassName={lastNameObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleLastNameChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title">
          <label htmlFor="suffix"> Suffix: </label>
        </div>
        <TextInput
          id="suffix"
          placeholder="III"
          inputClassName='soft-border shadow-bottom-right'
          value={suffixObj.value}
          size='10'
          errorMessage={suffixObj.errorMessage}
          errorClassName={suffixObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleSuffixChange(e.target.value)}
        />
      </div>
    </div>
  );
}
