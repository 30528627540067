import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserName from './UserName';
import UserAddress from './UserAddress';
import UserContact from './UserContact';
import UserDates from './UserDates';
import UserPassword from './UserPassword';
import WizardNavigator from '../menus/WizardNavigator';
import './UserWizard.css';
import {
  getStarterSubSubValueObj,
  getStarterValueObj,
  getStarterSubValueObj
} from './getStartedFunctions';
import {
  callWebServicePost
} from '../../helperFunctions/webserviceFunctions';
import {
  handleTitleChange,
	handleFirstNameChange,
	handleLastNameChange,
	handleMiddleNameChange,
	handleSuffixChange,
	handleCountryChange,
	handleStateChange,
	handleCityChange,
	handleStr1Change,
	handleStr2Change,
	handleZipCodeChange,
	handleEmailChange,
	handleCountryCodeChange,
	handleAreaCodeChange,
	handleTelephonePrefixChange,
	handleLineNumberChange,
	handleBirthDateChange,
	handlePasswordChange
} from './UserWizardHelperFunctions';

export default function UserWizard({ setToken, setMessage }) {

  const navigate = useNavigate();
  const [ page,               setPage ]               = useState('start');
  const [ str1Obj,            setStr1Obj ]            = useState(getStarterSubValueObj('address', 'street_1'));
  const [ str2Obj,            setStr2Obj ]            = useState(getStarterSubValueObj('address', 'street_2'));
  const [ cityObj,            setCityObj ]            = useState(getStarterSubValueObj('address', 'city'));
  const [ stateObj,           setStateObj ]           = useState(getStarterSubValueObj('address', 'state', 'TX'));
  const [ countryObj,         setCountryObj ]         = useState(getStarterSubValueObj('address', 'country', 'USA'));
  const [ zipcodeObj,         setZipcodeObj ]         = useState(getStarterSubValueObj('address', 'zipcode'));
  const [ titleObj,           setTitleObj ]           = useState(getStarterSubValueObj('name', 'title'));
  const [ firstNameObj,       setFirstNameObj ]       = useState(getStarterSubValueObj('name', 'firstname', '', true));
  const [ middleNameObj,      setMiddleNameObj ]      = useState(getStarterSubValueObj('name', 'middlename'));
  const [ lastNameObj,        setLastNameObj ]        = useState(getStarterSubValueObj('name', 'lastname', '', true));
  const [ suffixObj,          setSuffixObj ]          = useState(getStarterSubValueObj('name', 'suffix'));
  const [ emailObj,           setEmailObj ]           = useState(getStarterSubValueObj('contact', 'email', '', true));
  const [ countryCodeObj,     setCountryCodeObj ]     = useState(getStarterSubSubValueObj('contact', 'phone', 'countryCode', '1'));
  const [ areaCodeObj,        setAreaCodeObj ]        = useState(getStarterSubSubValueObj('contact', 'phone', 'areaCode'));
  const [ telephonePrefixObj, setTelephonePrefixObj ] = useState(getStarterSubSubValueObj('contact', 'phone', 'telephonePrefix'));
  const [ lineNumberObj,      setLineNumberObj ]      = useState(getStarterSubSubValueObj('contact', 'phone', 'lineNumber'));
  const [ birthdateObj,       setBirthdateObj ]       = useState(getStarterSubValueObj('dates', 'birthdate'));
  const [ passwordObj,        setPasswordObj ]        = useState(getStarterValueObj('password', '', true));

  function getFullName(accountObj){
    let fullName = accountObj.name.title + " " +
    accountObj.name.firstname + " " +
    accountObj.name.middlename + " " +
    accountObj.name.lastname + " " +
    accountObj.name.suffix;
    return fullName.trim();
  }

  const handleClick = async (path) => {
    document.getElementById('new-account-wizard-container').scroll({top:0,behavior:'smooth'});
    const accountObj = {
      name:{
        title: titleObj.value,
        firstname:firstNameObj.value,
        middlename:middleNameObj.value,
        lastname:lastNameObj.value,
        suffix: suffixObj.value
      },
      address:{
        street_1:str1Obj.value,
        street_2:str2Obj.value,
        city:cityObj.value,
        state:stateObj.value,
        country:countryObj.value,
        zipcode:zipcodeObj.value
      },
      contact:{
        email:emailObj.value,
        phone:{
          countryCode:countryCodeObj.value,
          areaCode:areaCodeObj.value,
          telephonePrefix:telephonePrefixObj.value,
          lineNumber:lineNumberObj.value,
          display:countryCodeObj.value+' ('+areaCodeObj.value+') '+telephonePrefixObj.value+'-'+lineNumberObj.value
        }
      },
      dates:{
        birthdate:birthdateObj.value
      },
      password:passwordObj.value
    };

    sessionStorage.setItem('accountObj', JSON.stringify(accountObj));
    localStorage.setItem('accountObj', JSON.stringify(accountObj));

    if(path === 'SUBMIT'){
      const results = await callWebServicePost(accountObj, 'create/user');
      if(results?.message === 'success'){
        setToken(results);
        let fullName = getFullName(accountObj);
        setMessage("Welcome, " + results.name + ". ");
        sessionStorage.removeItem('accountObj', JSON.stringify(accountObj));
        localStorage.removeItem('accountObj', JSON.stringify(accountObj));
        sessionStorage.setItem('currentPath','/');
        navigate('/');
      } else {
        setMessage(results?.message);
        navigate('/loginForm');
      }
    } else {
      setPage(path);
    }
  }

  function nameIsValid(){
    let valid = titleObj.valid &&
      firstNameObj.valid &&
      middleNameObj.valid &&
      lastNameObj.valid &&
      suffixObj.valid;
    return valid;
  }
  function addressIsValid(){
    let valid = countryObj.valid &&
      stateObj.valid &&
      cityObj.valid &&
      str1Obj.valid &&
      str2Obj.valid &&
      zipcodeObj.valid;
    return valid;
  }
  function contactIsValid(){
    let valid = emailObj.valid &&
      countryCodeObj.valid &&
      areaCodeObj.valid &&
      telephonePrefixObj.valid &&
      lineNumberObj.valid;
    return valid;
  }
  function datesAreValid(){
    let valid = birthdateObj.valid;
    return valid;
  }
  function passwordIsValid(){
    let valid = passwordObj.valid;
    return valid;
  }

  let contents = <></>;
  try{
    document.getElementById('new-account-wizard-container').scroll({top:0,behavior:'smooth'});
  } catch(e) {
    // no action needed
  }
  switch(page){
    case 'address':
      contents=<>
        <UserAddress
          str1Obj={str1Obj} handleStr1Change={(newVal) => handleStr1Change(newVal, str1Obj, (obj)=>setStr1Obj(obj))}
          str2Obj={str2Obj} handleStr2Change={(newVal) => handleStr2Change(newVal, str2Obj, (obj)=>setStr2Obj(obj))}
          cityObj={cityObj} handleCityChange={(newVal) => handleCityChange(newVal, cityObj, (obj)=>setCityObj(obj))}
          stateObj={stateObj} handleStateChange={(newVal) => handleStateChange(newVal, stateObj, (obj)=>setStateObj(obj))}
          countryObj={countryObj} handleCountryChange={(newVal) => handleCountryChange(newVal, countryObj, (obj)=>setCountryObj(obj))}
          zipcodeObj={zipcodeObj} handleZipCodeChange={(newVal) => handleZipCodeChange(newVal, zipcodeObj, (obj)=>setZipcodeObj(obj))}
        />
        <WizardNavigator
          menuItems={[{title:'<< Previous',value:'name',active:true},{title:'Next >>',value:'contact',active:addressIsValid()}]}
          handleClick={handleClick}
        />
      </>;
      break;
    case 'name':
      contents=<>
        <UserName
          titleObj={titleObj} handleTitleChange={(newVal) => {handleTitleChange(newVal, titleObj, (obj)=>setTitleObj(obj))}}
          firstNameObj={firstNameObj} handleFirstNameChange={(newVal) => handleFirstNameChange(newVal, firstNameObj, (obj)=>setFirstNameObj(obj))}
          middleNameObj={middleNameObj} handleMiddleNameChange={(newVal) => handleMiddleNameChange(newVal, middleNameObj, (obj)=>setMiddleNameObj(obj))}
          lastNameObj={lastNameObj} handleLastNameChange={(newVal) => handleLastNameChange(newVal, lastNameObj, (obj)=>setLastNameObj(obj))}
          suffixObj={suffixObj} handleSuffixChange={(newVal) => handleSuffixChange(newVal, suffixObj, (obj)=>setSuffixObj(obj))}
        />
        <WizardNavigator
          menuItems={[{title:'<< Previous',value:'start', active:true},{title:'Next >>',value:'address',active:nameIsValid()}]}
          handleClick={handleClick}
        />
      </>;
      break;
    case 'contact':
      contents=<>
        <UserContact
          emailObj={emailObj} handleEmailChange={(newVal) => handleEmailChange(newVal, emailObj, (obj) => setEmailObj(obj))}
          countryCodeObj={countryCodeObj} handleCountryCodeChange={(newVal) => handleCountryCodeChange(newVal, countryCodeObj, (obj) => setCountryCodeObj(obj))}
          areaCodeObj={areaCodeObj} handleAreaCodeChange={(newVal) => handleAreaCodeChange(newVal, areaCodeObj, (obj) => setAreaCodeObj(obj))}
          telephonePrefixObj={telephonePrefixObj} handleTelephonePrefixChange={(newVal) => handleTelephonePrefixChange(newVal, telephonePrefixObj, (obj) => setTelephonePrefixObj(obj))}
          lineNumberObj={lineNumberObj} handleLineNumberChange={(newVal) => handleLineNumberChange(newVal, lineNumberObj, (obj) => setLineNumberObj(obj))}
        />
        <WizardNavigator
          menuItems={[{title:'<< Previous',value:'address',active:true},{title:'Next >>',value:'dates',active:contactIsValid()}]}
          handleClick={handleClick}
        />
      </>;
      break;
    case 'dates':
      contents=<>
        <UserDates
          birthdateObj={birthdateObj} handleBirthDateChange={(newVal) => handleBirthDateChange(newVal, birthdateObj, (obj) => setBirthdateObj(obj))}
        />
        <WizardNavigator
          menuItems={[{title:'<< Previous',value:'contact',active:true},{title:'Next >>',value:'password',active:datesAreValid()}]}
          handleClick={handleClick}
        />
      </>;
      break;
    case 'password':
      contents=<>
        <UserPassword
          passwordObj={passwordObj} handlePasswordChange={(newVal) => handlePasswordChange(newVal, passwordObj, (obj) => setPasswordObj(obj))}
        />
        <WizardNavigator
          menuItems={[{title:'<< Previous',value:'dates', active:true},{title:'SUBMIT',value:'SUBMIT',active:passwordIsValid()}]}
          handleClick={handleClick}
        />
      </>;
      break;
    case 'SUBMIT':
      contents=<><h1>SUBMIT</h1></>;
      break;
    default:
      contents = <div className='wizard-nav-button-container'>
      <div className='href-link wizard-nav-button' onClick={()=>setPage('name')}> Start &gt;&gt; </div></div>;
  }

  return(
		<div id="new-account-wizard-container" className="section-container wizard">
      <div className='wizard-title'>User Wizard</div>
      {contents}

		</div>
  );
}





















// import React from 'react';
// import { Outlet } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
//
//
// export default function UserWizard() {
//   const navigate = useNavigate();
//
//   const handleClick = (path) => {
//     sessionStorage.setItem('wizPath',path);
//     navigate(path);
//   }
//
//   return(
// 		<div className="section-container wizard">
//       <fieldset>
//       <legend>User Wizard</legend>
//       <Outlet />
//       </fieldset>
// 		</div>
//   );
// }








//
// <input type='text' style={{fontSize:'24pt',width:'7px'}}/> px<br/>
// <input type='text' style={{fontSize:'24pt',width:'7pt'}}/> pt<br/>
// <input type='text' style={{fontSize:'24pt',width:'7ex'}}/> ex<br/>
// <input type='text' size='7' style={{fontSize:'24pt'}}/> size = 7 <br/>
// <input type='text' style={{fontSize:'24pt',width:'7em'}}/> em <br/>
// <input type='text' style={{fontSize:'24pt',width:'7pc'}}/> pc<br/>
// <input type='text' style={{fontSize:'24pt',width:'7cm'}}/> cm<br/>
// <input type='text' style={{fontSize:'24pt',width:'7in'}}/> in<br/>
