export const loginUser = async(credentials) => {
	return await callWebServicePost(credentials, 'login');
}

export const logoutUser = async(token) => {
	return await callWebServiceGet(token, 'logout');
	// let url = '/logout/'+token;
	// return fetch(url, {
	// 	method: 'GET',
	// 	mode: 'cors'
	// })
	// 	.then(data => data.json());
}

export const getIsEmailUniqueFromWebService = (emailAddress, callback) => {
	callWebServiceGet(emailAddress, 'isEmailUnique', callback);
	// const httpRequest = new XMLHttpRequest();
	// httpRequest.onreadystatechange = () => {
	// 	if (httpRequest.readyState === XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
	// 		if (httpRequest.status === 200) {
	// 			const text = httpRequest.responseText;
	// 			callback(text);
	// 		}
	// 	}
	// }
	// const url="/isEmailUnique/" + emailAddress;
	// httpRequest.open("GET",url, true); // use false for synchronous
	// httpRequest.send();
}

// exports.callWebServicePost = async (dataObj, route) => {
//  return fetch('/' + route + '/', {
// 	method: 'POST',
// 	mode: 'cors',
// 	headers: {'Content-Type': 'application/json'},
// 	body: JSON.stringify(dataObj)
//  })
//    .then(data => data.json())
// }

const getRootURL = () => {
	if(process.env.REACT_APP_ROOT_URL){
		return process.env.REACT_APP_ROOT_URL;
	}
	return '';
}

export const callWebServicePost = async (dataObj, route, callBack) => {
	const fullURL = getRootURL() + '/' + route + '/';
	if(callBack){
		fetch(
		 	fullURL,
			{
				method: 'POST',
				mode: 'cors',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(dataObj)
			}
		).then(data => callBack(data));
	} else {
		return fetch(
		 	fullURL,
			{
				method: 'POST',
				mode: 'cors',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(dataObj)
			}
		).then(data => data.json())
	}
}

export const callWebServiceGet = async (value, route, callBack) => {
	const fullURL = getRootURL() + '/' + route + '/' + value;
	if(callBack){
		fetch(
		 	fullURL,
			{
				method: 'GET',
				mode: 'cors'
			}
		).then(data => callBack(data));
	} else {
		return fetch(
		 	fullURL,
			{
				method: 'GET',
				mode: 'cors'
			}
		).then(data => data.json())
	}
}
