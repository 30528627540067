export const capitalizeFirstLetter = (val) => {
	if(val && val.trim() !== ''){
		let newVal = val.substr(0,1).toUpperCase();
		newVal += val.substr(1);
		return newVal;
	}
	return '';
}

export const valueIsEmpty = (val) => {
	if(!val){
		return true;
	} else if(val === undefined){
		return true;
	} else if(val.trim() === ''){
		return true;
	}
	return false;
}

export const validateRequiredValue = (val, valObj) => {
	let obj = {
		valid: true
	}
	if(val.trim().length < 1){
		obj.value = '';
		obj.errorMessage = 'Value is Required';
		obj.errorClassName = 'text-input-error';
		obj.valid = false;
	} else {
		obj.value = val;
		obj.errorMessage = '';
		obj.errorClassName = 'no-error';
	}
	return obj;
}

// export const callWebServicePost = async (dataObj, route, callBack) => {
//  return fetch('/' + route + '/', {
// 	method: 'POST',
// 	mode: 'cors',
// 	headers: {'Content-Type': 'application/json'},
// 	body: JSON.stringify(dataObj)
//  })
//    .then(data => {
// 		 if(callBack){
// 			 // console.log('callBack is not null.', data.json);
// 			 callBack(data.json);
// 		 }
// 		return data.json();
// 	 });
// }

export const getIsEmailUniqueFromWebService = (emailAddress, callback) => {
	const httpRequest = new XMLHttpRequest();
	httpRequest.onreadystatechange = () => {
		if (httpRequest.readyState === XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
			if (httpRequest.status === 200) {
				const text = httpRequest.responseText;
				callback(text);
			}
		}
	}
	const url="http://localhost:3260/isEmailUnique/" + emailAddress;
	httpRequest.open("GET",url, true); // use false for synchronous
	httpRequest.send();
}

export const emailIsInCorrectFormat = (email) => {
	var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regexp.test(email);
}

export const valueHasNoMoreThanOneDash = (val) => {
	let secondDashIndex = val.indexOf('-', val.indexOf('-')+1);
	if(secondDashIndex>=0){
		return false;
	}
	return true;
}

export const valueIsDash = (val) => {
	return val === '-';
}

export const valueIsNumber = (val) => {
	return !isNaN(val);
}

export const valueIsInteger = (val) => {
	return Number.isInteger(parseInt(val));
}

export const valueIsCorrectLength = (val, length) => {
	// if value less than length return -1
	// if value equals length return 0
	// if value more than length return 1
	if(val.trim().length < length){
		return -1;
	}
	if(val.trim().length > length) {
		return 1;
	}
	return 0;
}

export const valueHasSpecialCharacter = (val) => {
	var specRegExp = /(?=.*[!@#$%^&*()])/;
	return specRegExp.test(val);
}

export const valueHasCapitalLetter = (val) => {
	var capRegExp = /(?=.*[A-Z])/;
	return capRegExp.test(val);
}

export const valueHasLowercaseLetter = (val) => {
	var lowRegExp = /(?=.*[a-z])/;
	return lowRegExp.test(val);
}

export const valueHasNumeral = (val) => {
	var numRegExp = /(?=.*\d)/;
	return numRegExp.test(val);
}

export const valueHasSpaces = (val) => {
	var whiteSpaceRegExp = /(?=.*\s)/;
	return whiteSpaceRegExp.test(val);
}

export function setUnvalidatedValue(newVal, valObj, callBack){
	let obj = {}
	obj.value = newVal;
	obj.valid = valObj.valid;
	obj.errorMessage = valObj.errorMessage;
	obj.errorClassName = valObj.errorClassName;
	callBack(obj);
}

export function handleNameChange(newVal, valObj, callBack){
	callBack(validateRequiredValue(newVal, valObj));
}
export function handleDescriptionChange(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}

export function getToken(){
	const sObj = JSON.parse(sessionStorage.getItem('token'));
	if(sObj.token){
		return sObj.token;
	}
	return null;
}

// module.exports = {
// 	handleNameChange,
// 	callWebServicePost,
// 	handleDescriptionChange,
// 	getToken
// }
