import React from 'react'

export default function WizardNavigator({
	menuItems,
	handleClick
}) {
	let counter=1000;
	return(
		<div className='wizard-nav-button-container'>
			{
				(menuItems)?menuItems.map(menuItem => {
						let value=menuItem.value;
						let title=menuItem.title;
						let active=menuItem.active;
						let class_name = (active)?'href-link wizard-nav-button':'href-link wizard-nav-button inactive-link';
						let handle_click=(active)?()=>handleClick(value):()=>alert('Form is not valid.');
						return (<div key={counter++} className={class_name} onClick={handle_click}> {title} </div>);
					}
				):''
			}
		</div>
	);
}
