import React from 'react';
import LoginRequired from '../Login/LoginRequired';

export default function UpdateBudget() {
  if(!sessionStorage.getItem('token')){
    return(
      <LoginRequired />
    );
  }
  return(
		<div className="section-container budget">
    	<h2>Update Budget</h2>
		</div>
  );
}
