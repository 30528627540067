import React from 'react';
import './Message.css';



const Messages = () => {

  return(
    <div className='message-container'>
			<h1> {sessionStorage.getItem('message')} </h1>
		</div>
  )
}

export default Messages;
