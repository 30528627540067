export const getStarterValue = (val, defaultVal='') => {
	if(sessionStorage.accountObj && JSON.parse(sessionStorage.accountObj)[val]){
		return JSON.parse(sessionStorage.accountObj)[val];
	} else if(localStorage.accountObj && JSON.parse(localStorage.accountObj)[val]){
		return JSON.parse(localStorage.accountObj)[val];
	}
	return defaultVal;
}
export const getStarterSubValue = (cat, val, defaultVal='') => {
	if(sessionStorage.accountObj && JSON.parse(sessionStorage.accountObj)[cat]){
		return JSON.parse(sessionStorage.accountObj)[cat][val];
	} else if(localStorage.accountObj && JSON.parse(localStorage.accountObj)[cat]){
		return JSON.parse(localStorage.accountObj)[cat][val];
	}
	return defaultVal;
}
export const getStarterSubSubValue = (cat, obj, val, defaultVal='') => {
	if(sessionStorage.accountObj && JSON.parse(sessionStorage.accountObj)[cat] && JSON.parse(sessionStorage.accountObj)[cat][obj]){
		return JSON.parse(sessionStorage.accountObj)[cat][obj][val];
	} else if(localStorage.accountObj && JSON.parse(localStorage.accountObj)[cat] && JSON.parse(sessionStorage.accountObj)[cat][obj]){
		return JSON.parse(localStorage.accountObj)[cat][obj][val];
	}
	return defaultVal;
}
export const getStarterValueObj = (val, defaultVal='', required=false) => {
	let pObj = {
		value: defaultVal,
		errorMessage: '',
		errorClassName: 'no-error',
		valid:true
	}
	if(sessionStorage.accountObj && JSON.parse(sessionStorage.accountObj)[val]){
		pObj.value = JSON.parse(sessionStorage.accountObj)[val];
	} else if(localStorage.accountObj && JSON.parse(localStorage.accountObj)[val]){
		pObj.value = JSON.parse(localStorage.accountObj)[val];
	}
	try{
		if(required && pObj.value.trim() === ''){
			pObj.valid = false;
		}
	} catch(e){
		console.log('ERROR!!!!! ', e);
	}
	return pObj;
}
export const getStarterSubValueObj = (cat, val, defaultVal='', required=false) => {
	let pObj = {
		value:defaultVal,
		errorMessage:'',
		errorClassName:'no-error',
		valid:true
	}
	if(sessionStorage.accountObj && JSON.parse(sessionStorage.accountObj)[cat]){
		pObj.value = JSON.parse(sessionStorage.accountObj)[cat][val];
	} else if(localStorage.accountObj && JSON.parse(localStorage.accountObj)[cat]){
		pObj.value = JSON.parse(localStorage.accountObj)[cat][val];
	}
	try{
		if(required && pObj.value.trim() === ''){
			pObj.valid = false;
		}
	} catch(e){
		console.log('ERROR!!!!! ', e);
	}
	return pObj;
}
export const getStarterSubSubValueObj = (cat, obj, val, defaultVal='', required=false) => {
	let pObj = {
		value:defaultVal,
		errorMessage:'',
		errorClassName:'no-error',
		valid:true
	}
	if(sessionStorage.accountObj && JSON.parse(sessionStorage.accountObj)[cat] && JSON.parse(sessionStorage.accountObj)[cat][obj]){
		pObj.value = JSON.parse(sessionStorage.accountObj)[cat][obj][val];
	} else if(localStorage.accountObj && JSON.parse(localStorage.accountObj)[cat] && JSON.parse(sessionStorage.accountObj)[cat][obj]){
		pObj.value = JSON.parse(localStorage.accountObj)[cat][obj][val];
	}
	try{
		if(required && pObj.value.trim() === ''){
			pObj.valid = false;
		}
	} catch(e){
		console.log('ERROR!!!!! ', e);
	}
	return pObj;
}

// module.exports = { getStarterValue, getStarterSubValue, getStarterSubSubValue, getStarterValueObj, getStarterSubValueObj, getStarterSubSubValueObj }
