import React from 'react';
import TextInput from '../../formElements/TextInput';
import StateSelect from '../../formElements/StateSelect';

export default function UserAddress({
  str1Obj, handleStr1Change,
  str2Obj, handleStr2Change,
  cityObj, handleCityChange,
  stateObj, handleStateChange,
  countryObj, handleCountryChange,
  zipcodeObj, handleZipCodeChange
}) {

  const usaArray = ['united states', 'united states of america', 'usa', 'us'];
  const stateFormElement = (!countryObj?.value || countryObj?.value.trim() === '' || usaArray.includes(countryObj?.value.trim().toLowerCase()))?
    <StateSelect
      value={stateObj.value}
      onChangeFunct={e => handleStateChange(e.target.value)}
      selectClassName={'state-select soft-border shadow-bottom-right'}
      optionClassName={'state-select-option'}
      containerClass={'form-data'}
    />:
    <TextInput
      id="state"
      type="text"
      placeholder='state name'
      inputClassName='soft-border shadow-bottom-right'
      value={stateObj.value}
      size='25'
      errorMessage={stateObj.errorMessage}
      errorClassName={stateObj.errorClassName}
      containerClass="form-data"
      onChangeFunct={(e) => handleStateChange(e.target.value)}
    />

  return(
    <div className="wizard-container user address">
      <div className="page-count-container">
        <div className="page-count-label">2/5</div>
      </div>
      <div className="form-item-container">
        <div className="form-title"><label htmlFor="country"> Country: </label></div>
        <TextInput
          id="country"
          type="text"
          placeholder='country name'
          inputClassName='soft-border shadow-bottom-right'
          value={(!countryObj.value || countryObj.value.trim() ==='')?'USA':countryObj.value}
          size='25'
          errorMessage={countryObj.errorMessage}
          errorClassName={countryObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={e => handleCountryChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title"><label htmlFor="state"> State: </label></div>
        {stateFormElement}
      </div>
      <div className="form-item-container">
        <div className="form-title"><label htmlFor="city"> City: </label></div>
        <TextInput
          id="city"
          type="text"
          placeholder='city name'
          inputClassName='soft-border shadow-bottom-right'
          value={cityObj.value}
          size='25'
          errorMessage={cityObj.errorMessage}
          errorClassName={cityObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={e => handleCityChange(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title"><label htmlFor="street_1"> Street: </label></div>
        <TextInput
          id="street_1"
          type="text"
          placeholder='street address'
          inputClassName='soft-border shadow-bottom-right'
          value={str1Obj.value}
          size='25'
          errorMessage={str1Obj.errorMessage}
          errorClassName={str1Obj.errorClassName}
          containerClass="form-data"
          onChangeFunct={e => handleStr1Change(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title"><label htmlFor="street_2"> Extra Street: </label></div>
        <TextInput
          id="street_2"
          type="text"
          placeholder='Suite 113'
          inputClassName='soft-border shadow-bottom-right'
          value={str2Obj.value}
          size='25'
          errorMessage={str2Obj.errorMessage}
          errorClassName={str2Obj.errorClassName}
          containerClass="form-data"
          onChangeFunct={e => handleStr2Change(e.target.value)}
        />
      </div>
      <div className="form-item-container">
        <div className="form-title"><label htmlFor="zipcode"> Zip Code: </label></div>
        <TextInput
          id="zipcode"
          type="text"
          placeholder='12345'
          inputClassName='soft-border shadow-bottom-right'
          value={zipcodeObj.value}
          size='25'
          maxLength='10'
          errorMessage={zipcodeObj.errorMessage}
          errorClassName={zipcodeObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={e => handleZipCodeChange(e.target.value)}
        />
      </div>
    </div>
  );
}
