import { useState } from 'react';

export default function useToken() {
  const sessionStorageToken = JSON.parse(sessionStorage.getItem('token'));
  const [token, setToken] = useState(sessionStorageToken?.token);

  const saveToken = userToken => {
		sessionStorage.setItem('message', userToken.message);
		if(userToken.token === null || userToken.token === 'null'){
      sessionStorage.removeItem('token');
      let tempToken = JSON.parse(sessionStorage.getItem('token'))?.token;
      if(tempToken === undefined){
        tempToken = null;
      }
			setToken(tempToken);
		} else {
			const userTokenString = JSON.stringify(userToken);
	    sessionStorage.setItem('token', userTokenString);
			setToken(userToken.token);
		}
  }

  return {
    setToken: saveToken,
    token
  }
}
