import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './components/Header/Header'
import Body from './components/Body/Body'
import Dashboard from './components/Dashboard/Dashboard';
import Preferences from './components/Preferences/Preferences';
import Login from './components/Login/Login';
import Works from './components/Works/Works';
import Helps from './components/Helps/Helps';
import Share from './components/Share/Share';

import Footer from './components/Footer/Footer'
import Contact from './components/Footer/Contact';
import About from './components/Footer/About';
import Other from './components/Footer/Other';

import Budget from './components/Budget/Budget';
import CreateBudget from './components/Budget/create/CreateBudget';
import UpdateBudget from './components/Budget/UpdateBudget';
import ListBudget from './components/Budget/ListBudget';
import DeleteBudget from './components/Budget/delete/DeleteBudget';

import Contributions from './components/Contributions/Contributions';
import CreateContribution from './components/Contributions/CreateContribution';
import UpdateContribution from './components/Contributions/UpdateContribution';
import ListContribution from './components/Contributions/ListContribution';
import DeleteContribution from './components/Contributions/DeleteContribution';

import Contributors from './components/Contributors/Contributors';
import CreateContributor from './components/Contributors/CreateContributor';
import UpdateContributor from './components/Contributors/UpdateContributor';
import ListContributor from './components/Contributors/ListContributor';
import DeleteContributor from './components/Contributors/DeleteContributor';

import Activities from './components/Activities/Activities';
import CreateActivity from './components/Activities/CreateActivity';
import UpdateActivity from './components/Activities/UpdateActivity';
import ListActivity from './components/Activities/ListActivity';
import DeleteActivity from './components/Activities/DeleteActivity';

import UserWizard from './components/wizards/user/UserWizard';
import useToken from './hooks/useToken';
import useMessage from './hooks/useMessage';
import useBudgetObj from './hooks/useBudgetObj';


function App() {
  const { token, setToken } = useToken();
  const { budgetObj, setBudgetObj } = useBudgetObj();
  const { setMessage } = useMessage();

  return (
    <BrowserRouter>
    <div className="main-content">
      <div className="App">
        <Header setToken={setToken} setBudgetObj={setBudgetObj} />
          <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/preferences" element={<Preferences />} />
            <Route path='/works' element={<Works />} />
            <Route path='/helps' element={<Helps />} />
            <Route path='/share' element={<Share />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<About />} />
            <Route path='/other' element={<Other />} />
            <Route path='/loginForm' element={<Login setToken={setToken} setMessage={setMessage} />} />
            <Route path='/febudget' element={<Budget />} />
            <Route path='/fecreatebudget' element={<CreateBudget setToken={setToken} setMessage={setMessage} />} />
            <Route path='/feupdatebudget' element={<UpdateBudget />} />
            <Route path='/felistbudget' element={<ListBudget />} />
            <Route path='/fedeletebudget' element={<DeleteBudget />} />
            <Route path='/fecontributions' element={<Contributions />} />
            <Route path='/fecreatecontribution' element={<CreateContribution />} />
            <Route path='/feupdatecontribution' element={<UpdateContribution />} />
            <Route path='/felistcontributions' element={<ListContribution />} />
            <Route path='/fedeletecontribution' element={<DeleteContribution />} />
            <Route path='/feactivities' element={<Activities />} />
            <Route path='/fecreateactivity' element={<CreateActivity />} />
            <Route path='/feupdateactivity' element={<UpdateActivity />} />
            <Route path='/felistactivities' element={<ListActivity />} />
            <Route path='/fedeleteactivity' element={<DeleteActivity />} />
            <Route path='/fecontributors' element={<Contributors />} />
            <Route path='/fecreatecontributor' element={<CreateContributor />} />
            <Route path='/feupdatecontributor' element={<UpdateContributor />} />
            <Route path='/felistcontributors' element={<ListContributor />} />
            <Route path='/fedeletecontributor' element={<DeleteContributor />} />
            <Route path='/feuserwizard/*' element={<UserWizard setToken={setToken} setMessage={setMessage} />} >
            </Route>
          </Routes>
        <Footer />
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;

/*
FOR ROUTER references: https://reactrouter.com/en/main/upgrading/v5
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="users/*" element={<Users />} /> <==== use this to have sub menus (see User function below)
      </Routes>
    </BrowserRouter>
  );
}

function Users() {
  return (
    <div>
      <nav>
        <Link to="me">My Profile</Link>
      </nav>

      <Routes>
        <Route path=":id" element={<UserProfile />} />
        <Route path="me" element={<OwnUserProfile />} />
      </Routes>
    </div>
  );
}
*/
