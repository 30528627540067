import React from 'react';
import './formElements.css';

export default function TextInput({
	id, type, placeholder,
	inputClassName, min, max,
	maxlength, size, value,
	errorMessage, errorClassName,
	containerClass, onChangeFunct,
	autocomplete
}){

	return(
		<div className={containerClass}>
			<input
				id={id}
				name={id}
				type={type}
				placeholder={placeholder}
				className={inputClassName}
				min={(min)?min:''}
				max={(max)?max:''}
				maxLength={(maxlength)?maxlength:''}
				size={(size)?size:''}
				value={value}
				autoComplete={autocomplete}
				onChange={onChangeFunct}
			/>
			<div className={errorClassName}>
				{errorMessage}
			</div>
		</div>
	);
}
