import { useState } from 'react';

export default function useBudgetObj() {
  const sessionStorageBudgetObj = JSON.parse(sessionStorage.getItem('budgetObj'));
  const [budgetObj, setBudgetObj] = useState(sessionStorageBudgetObj?.budgetObj);

  const saveBudgetObj = userBudgetObj => {
		if(userBudgetObj.budgetObj === null || userBudgetObj.budgetObj === 'null'){
      sessionStorage.removeItem('budgetObj');
      let tempBudgetObj = JSON.parse(sessionStorage.getItem('budgetObj'))?.budgetObj;
      if(tempBudgetObj === undefined){
        tempBudgetObj = null;
      }
			setBudgetObj(tempBudgetObj);
		} else {
			const userBudgetObjString = JSON.stringify(userBudgetObj);
	    sessionStorage.setItem('budgetObj', userBudgetObjString);
			setBudgetObj(userBudgetObj.budgetObj);
		}
  }

  return {
    setBudgetObj: saveBudgetObj,
    budgetObj
  }
}
