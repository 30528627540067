import React from 'react';
import Messages from '../Messages/Messages';

const Body = () => {
  return(
    <div className='section-container body'>
      <Messages />
      
    </div>
  )
}

export default Body;
