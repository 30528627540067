import React from 'react';
import { useNavigate } from 'react-router-dom';

const ContextMenu = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    sessionStorage.setItem('subPath',path);
    navigate(path);
  }

  const currentPath = sessionStorage.getItem('currentPath');

  switch(currentPath){
    case '/febudget':
      return(
        <div className='contextMenu-container'>
          <div className='title menu context'>Budget:</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fecreatebudget')}>Create</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/feupdatebudget')}>Update</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/felistbudget')}>Show</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fedeletebudget')}>Delete</div>
        </div>
      )

    case '/fecontributors':
      return(
        <div className='contextMenu-container'>
          <div className='title menu context'>Contributors:</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fecreatecontributor')}>Create</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/feupdatecontributor')}>Update</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/felistcontributors')}>List</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fedeletecontributor')}>Delete</div>
        </div>
      )

    case '/feactivities':
      return(
        <div className='contextMenu-container'>
          <div className='title menu context'>Activities:</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fecreateactivity')}>Create</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/feupdateactivity')}>Update</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/felistactivities')}>List</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fedeleteactivity')}>Delete</div>
        </div>
      )

    case '/fecontributions':
      return(
        <div className='contextMenu-container'>
          <div className='title menu context'>Contributions:</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fecreatecontribution')}>Create</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/feupdatecontribution')}>Update</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/felistcontributions')}>List</div>
          <div className='userMenu-button gradient' onClick={() => handleClick('/fedeletecontribution')}>Delete</div>
        </div>
      )

    default:
      return(<></>);
  }
}

export default ContextMenu;
